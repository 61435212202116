<template>
<div class="report report-labarugi">
    <div class="card bg-opacity-0">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title row text-gray-600">
                    <div class="d-flex p-0 align-items-center position-relative mb-4">
                        <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.go(-1)"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0 tab-content bg-transparent">
            
            <LoadingSummary v-if="loadingSummary" />
            <div v-else>
                <div class="summary">
                    <div class="summary-list-fixed row m-0">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 fs-4 fw-bolder text-gray-700">
                            <div class="d-flex">
                                <span class="text-uppercase">Total Qty</span>
                                <span class="ms-auto">{{ summary.total_qty }} PCS</span>
                            </div>
                            <div class="d-flex">
                                <span class="text-uppercase">Total PNL</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_profit, {prefix: 'Rp'}) }}</span>
                            </div>
                            <div class="d-flex">
                                <span class="text-uppercase">ROE</span>
                                <span class="ms-auto">{{ summary.roe.toFixed(2) }}%</span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 fs-4 fw-bolder text-gray-700">
                            <div class="d-flex text-gray-500">
                                <span class="text-uppercase">Total Harga Tag</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_gross, { prefix: 'Rp'}) }}</span>
                            </div>
                            <div class="d-flex text-gray-500">
                                <span class="text-uppercase">Total Diskon</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_discount, { prefix: 'Rp' }) }}</span>
                            </div>
                            <div class="d-flex">
                                <span class="text-uppercase">Total Harga Jual</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_nett, { prefix: 'Rp'}) }}</span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 fs-4 fw-bolder text-gray-700">
                            <div class="d-flex text-gray-500">
                                <span class="text-uppercase">Total HPP Item</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_hpp_item, { prefix: 'Rp'}) }}</span>
                            </div>
                            <div class="d-flex text-gray-500">
                                <span class="text-uppercase">Total HPP Desain</span>
                                <span class="ms-auto ">{{ formatIDR(summary.total_hpp_desain, { prefix: 'Rp' }) }}</span>
                            </div>
                            <div class="d-flex">
                                <span class="text-uppercase">Total HPP</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_hpp_item + summary.total_hpp_desain, { prefix: 'Rp' }) }}</span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 fs-4 fw-bolder text-gray-700">
                            <div class="d-flex">
                                <span class="text-uppercase">Total Payment Konsi</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_payment_konsi, { prefix: 'Rp'}) }}</span>
                            </div>
                            <div class="d-flex">
                                <span class="text-uppercase">Total PNL Konsi</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_profit_konsi, { prefix: 'Rp' }) }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_1_header_1">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_1_body_1"
                        aria-expanded="true"
                        aria-controls="kt_accordion_1_body_1"
                      >
                        Brand
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_1_body_1"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_1_header_1"
                      data-bs-parent="#kt_accordion_1"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.brand" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.brand_name }}</span>
                                            <span class="ms-auto">{{ o.qty }} PCS</span>
                                        </div>
                                        <div class="py-2">
                                            <ul class="ps-3 mt-1 text-gray-600 fs-7">
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total Harga Jual</div>
                                                        <div>{{ formatIDR(o.total, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total HPP</div>
                                                        <div>{{ formatIDR(o.total_hpp_item + o.total_hpp_desain, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total PNL</div>
                                                        <div>{{ formatIDR(o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">ROE</div>
                                                        <div>{{ (((o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee) / o.total) * 100).toFixed(2) }}%</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_2">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_2_header_2">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_body_2"
                        aria-expanded="true"
                        aria-controls="kt_accordion_2_body_2"
                      >
                        Kategori
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_2_body_2"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_2_header_2"
                      data-bs-parent="#kt_accordion_2"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.category" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.category }}</span>
                                            <span class="ms-auto">{{ o.qty }} PCS</span>
                                        </div>
                                        <div class="py-2">
                                            <ul class="ps-3 mt-1 text-gray-600 fs-7">
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total Harga Jual</div>
                                                        <div>{{ formatIDR(o.total, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total HPP</div>
                                                        <div>{{ formatIDR(o.total_hpp_item + o.total_hpp_desain, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total PNL</div>
                                                        <div>{{ formatIDR(o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">ROE</div>
                                                        <div>{{ (((o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee) / o.total) * 100).toFixed(2) }}%</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_3">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_3_header_3">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_3_body_3"
                        aria-expanded="true"
                        aria-controls="kt_accordion_3_body_3"
                      >
                        Toko
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_3_body_3"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_3_header_3"
                      data-bs-parent="#kt_accordion_3"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.store" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.store_name }}</span>
                                            <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                        </div>
                                        <div class="py-2">
                                            <ul class="ps-3 mt-1 text-gray-600 fs-7">
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total Harga Jual</div>
                                                        <div>{{ formatIDR(o.total, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total HPP</div>
                                                        <div>{{ formatIDR(o.total_hpp_item + o.total_hpp_desain, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li v-if="o.total_beban_shopee">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total Beban Shopee ({{ o.beban_shopee }})</div>
                                                        <div>{{ formatIDR(o.total_beban_shopee, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">Total PNL</div>
                                                        <div>{{ formatIDR(o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee, { prefix: 'Rp' }) }}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-uppercase">ROE</div>
                                                        <div>{{ (((o.total - o.total_hpp_item - o.total_hpp_desain - o.total_beban_shopee) / o.total) * 100).toFixed(2) }}%</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->
            </div>
        </div>
    </div>

</div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileDownload from 'js-file-download';
import Axios from 'axios'
import moment from 'moment'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import LoadingSummary from "@/components/LoadingSummary.vue"

export default defineComponent({
    components: { 
        //Field,
        //Form,
        //ErrorMessage,
        //'inner-image-zoom': InnerImageZoom,
        LoadingSummary,
    },
    setup() {
        const route = useRoute()
        const router = useRouter()

        const store = useStore()
        const loadingSummary = ref(false)

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        moment.locale('id');
        
        const summary = ref({
          total_item: 0,
          total_qty: 0,
          total_gross: 0,
          total_beban_shopee: 0,
          total_sharing_profit: 0,
          total_hpp_item: 0,
          total_hpp_desain: 0,
          total_nett: 0,
          total_profit: 0,
          total_payment_konsi: 0,
          total_profit_konsi: 0,
          roe: 0,
          store: 0,
          tipe_produk: [],
          brand: [],
          category: [],
          artikel: [],
          color: [],
          size: [],
          payment: [],
        })

        const getSummary = async () => {
            try {
                loadingSummary.value = true

                await ApiService.get("summary_laba_rugi")
                .then(async({ data }) => {
                    summary.value.total_qty = data.data.total_qty || 0
                    summary.value.total_gross = data.data.total_gross
                    summary.value.total_discount = data.data.total_discount
                    summary.value.total_beban_shopee = data.data.total_beban_shopee
                    summary.value.total_nett = data.data.total_nett
                    summary.value.sharing_profit = data.data.sharing_profit
                    summary.value.total_profit = data.data.total_profit
                    summary.value.total_payment_konsi = data.data.total_payment_konsi
                    summary.value.total_profit_konsi = data.data.total_profit_konsi
                    summary.value.roe = data.data.total_nett > 0 ? (data.data.total_profit / data.data.total_nett) * 100 : 0;
                    summary.value.total_hpp_item = data.data.total_hpp_item
                    summary.value.total_hpp_desain = data.data.total_hpp_desain
                    summary.value.brand = data.data.brand
                    summary.value.category = data.data.category
                    summary.value.store = data.data.store
                })
                .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                });

            } catch(e) {
                console.log(e)
            }

            loadingSummary.value = false
        }

        onBeforeMount(() => {
            setCurrentPageBreadcrumbs(route.meta.title, ['Laporan Laba Rugi', route.meta.title]);

            getSummary()
        })

        return {
            moment,
            router,
            loadingSummary,
            img_url_mockup, img_url_desain,
            summary,
            formatIDR,
        }
    }
})
</script>